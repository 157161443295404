import React, { useReducer } from "react"

import { RefillsReducer } from "./RefillsReducer"
import { useAirtableRefillsFormFields } from "./hooks/useAirtableRefillsFormFields"

import { generateInitialValues } from "../../../../services/context"

let { flattenNode } = require("../../../../services/arrays")

const RefillsContext = React.createContext()

const RefillsProvider = ({ children }) => {
  let formFields = useAirtableRefillsFormFields()
  formFields = flattenNode(formFields?.allAirtableallRefillsFormFields)
  let [refillsState, refillsDispatch] = useReducer(RefillsReducer, {
    ...generateInitialValues({ fields: formFields }),
    documents: [],
    medicines: [],
    consentAndAuthorization: [],
    updatedPrescription: {
      medicines: [],
      cancelledMedicines: [],
      agreeToConsent: [],
    },
    updatePersonalDetails: {
      deliveryAddress: {
        addressType: "",
        streetAddress: "",
        houseNumber: "",
        officeAddress: {
          label: "",
          value: "",
        },
        province: {
          label: "",
          value: "",
        },
        city: {
          label: "",
          value: "",
        },
        barangay: {
          label: "",
          value: "",
        },
        notes: "",
      },
    },
    initialState: {
      ...generateInitialValues({ fields: formFields }),
      documents: [],
      medicines: [],
      consentAndAuthorization: [],
      updatedPrescription: {
        medicines: [],
        cancelledMedicines: [],
        agreeToConsent: [],
      },
      updatePersonalDetails: {
        deliveryAddress: {
          addressType: "",
          streetAddress: "",
          houseNumber: "",
          officeAddress: {
            label: "",
            value: "",
          },
          province: {
            label: "",
            value: "",
          },
          city: {
            label: "",
            value: "",
          },
          barangay: {
            label: "",
            value: "",
          },
          notes: "",
        },
      },
    },
  })

  return (
    <RefillsContext.Provider value={{ refillsState, refillsDispatch }}>
      {children}
    </RefillsContext.Provider>
  )
}

export { RefillsContext, RefillsProvider }
